import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import API from "../../common/api";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import SubmenuButtons from "../../components/SubmenuButtons";
import { useHistory } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import EmployeeSearch from "./EmployeeSearch";
import { withRouter } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Employee() {
  const classes = useStyles();
  const history = useHistory();

  const [columns, setColumns] = useState([
    {
      title: "SL",
      field: "sl_no",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Employee ID",
      field: "employee_id",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Name",
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Mobile NO.",
      field: "mobile_no",
      cellStyle: {
        textAlign: "left",
      },
    },
    // {
    //   title: "Email",
    //   field: "email",
    //   cellStyle: {
    //     textAlign: "left",
    //     paddingRight: "32px",
    //   },
    //   headerStyle: {
    //     paddingRight: "32px",
    //   },
    // },
  ]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDisableBtn, setIsDisableBtn] = useState(false);

  const [permission, setPermission] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    setIsLoading(true);
    async function fetchData() {
      const res = await API.get("/users");
      setData(res.data);
    }
    fetchData();

    const app_user_permission = localStorage.getItem("app_user_permission");
    if (app_user_permission) {
      setPermission(app_user_permission);
      setIsLoading(false);
    }
  }, [permission]);

  const deleteEmployeeById = async (employee_id, name) => {
    alert(`Employee ID - ${employee_id} [${name}]\nAll data will be deleted!`);
    const res = await API.delete(`/users/${employee_id}`);
    if (res.success === 1) {
      history.go(0);
    }
    setIsDisableBtn(true);
  };

  const getEmployeeListPDF = async (columns, data) => {
    const doc = new jsPDF();

    const columnTitles = columns.map((columnDef) => {
      return columnDef.title;
    });

    const pdfData = data.map((rowData) =>
      columns.map((columnDef) => rowData[columnDef.field])
    );

    doc.autoTable({
      head: [columnTitles],
      body: pdfData,
    });

    doc.save(`EmployeeList.pdf`);
  };

  const getActions = () => {
    const actions = [];

    if (permission.includes("R")) {
      actions.push({
        icon: () => <InsertDriveFileOutlinedIcon />,
        tooltip: "View Documents",
        onClick: (event, rowData) => {
          history.push("/employeeViewDocument", {
            id: `${rowData.employee_id}`,
          });
        },
      });
    }

    if (permission.includes("R")) {
      actions.push({
        icon: () => <VisibilityOutlinedIcon />,
        tooltip: "View Details",
        onClick: (event, rowData) => {
          // console.log("ID " + rowData.id_pk);
          history.push("/employeeView", {
            id: `${rowData.employee_id}`,
          });

          // history.push("/employeeView", { id: 50 });
        },
      });
    }

    if (permission.includes("U")) {
      actions.push({
        icon: () => <EditOutlinedIcon />,
        tooltip: "Edit",
        onClick: (event, rowData) => {
          // console.log("ID " + rowData.id_pk);
          history.push("/employeeUpdate", {
            id: `${rowData.employee_id}`,
          });
          // history.push("/employeeView", { id: 50 });
        },
      });
    }

    if (permission.includes("D")) {
      actions.push({
        icon: () => <DeleteOutlineOutlinedIcon />,
        tooltip: "Delete User",
        onClick: (event, rowData) => {
          // console.log("ID " + rowData.id_pk); // history.push("/employeeUpdate", { id: `${rowData.employee_id}` });
          //  history.push("/employeeView", { id: 50 });
          if (!isDisableBtn) {
            deleteEmployeeById(rowData.employee_id, rowData.name);
          }
        },
      });
    }
    return actions;
  };
  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />
      <EmployeeSearch />
      <h1>Employee List</h1>
      {!isLoading && (
        <MaterialTable
          title=""
          columns={columns}
          data={data}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          options={{
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => {
              getEmployeeListPDF(columns, data);
            },
            search: false,
            pageSize: 20,
            pageSizeOptions: [50, 100, 200],
            actionsColumnIndex: -1,
            headerStyle: {
              textAlign: "left",
              paddingRight: "32px",
            },

            actionsCellStyle: {
              paddingRight: "32px",
              justifyContent: "center",
            },
          }}
          actions={!isLoading && getActions()}
        />
      )}
    </main>
  );
}

export default withRouter(Employee);
