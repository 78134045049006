export const employeeRoutes = {
  add: "/employeeAdd",
  importBulk: "/employeeImport",
  viewAll: "/employee",
  search: "/employeeSearch",
  setting: "/setting",
  idCard: "/employeeIdCard",
  salaryInfo: "/employeeSalaryAdd",
  fileUpload: "/employeeFileUpload",
  helpCenterAdd: "/helpCenterAdd",
};

export const guarantorRoutes = {
  add: "/guarantorAdd",
  importBulk: "/guarantorImport",
  viewAll: "/guarantor",
  search: "/guarantorSearch",
  setting: "",
};
