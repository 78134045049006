import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect } from "react";
import SubmenuButtons from "../../components/SubmenuButtons";
import { useForm, Controller } from "react-hook-form";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import API from "../../common/api";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
}));

function EmployeeAdd() {
  const classes = useStyles();
  const { control, register, handleSubmit, errors, watch } = useForm();
  const [picture, setPicture] = useState();
  const [pictureDB, setPictureDB] = useState("");
  const [signatureImg, setSignatureImg] = useState();
  const [signatureImgDB, setSignatureImgDB] = useState("");
  const history = useHistory();
  const onSubmit = async (data) => {
    // console.log(JSON.stringify(data));
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      // console.log(key, value);
      formData.set(key, value);
    }
    formData.set("profile_image", pictureDB);
    formData.set("signature", signatureImgDB);

    const res = await API.post("/users", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res.success === 1) {
      history.push("/employee");
    }
  };

  const imageHandler = (e) => {
    const { name } = e.target;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (name === "profile_image") {
          setPicture(reader.result);
          setPictureDB(e.target.files[0]);
        } else {
          setSignatureImg(reader.result);
          setSignatureImgDB(e.target.files[0]);
        }
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const [permission, setPermission] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [unitNames, setUnitNames] = useState([]);
  const [designations, setDesignations] = useState([]);
  useEffect(() => {
    setIsLoading(true);

    const app_user_permission = localStorage.getItem("app_user_permission");
    if (app_user_permission) {
      setPermission(app_user_permission);
    }

    async function fetchData() {
      const resUnitNames = await API.get(`/settings/unitName`);
      console.log("RES: " + JSON.stringify(resUnitNames));
      if (resUnitNames.data) {
        setUnitNames(resUnitNames.data);
      }

      const resDesignations = await API.get(`/settings/designation`);
      console.log("RES: " + JSON.stringify(resDesignations));
      if (resDesignations.data) {
        setDesignations(resDesignations.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [permission]);

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />
      <h1>Create New Employee</h1>
      <Paper className={classes.paper}>
        <List className={classes.root}>
          {/* autoComplete="off" */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container>
              <Grid item xs={12} sm={6}>
                {/* Employee ID */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="employee_id"
                        className={classes.labelText}
                      >
                        Employee ID:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="employee_id"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="employee_id"
                      />
                    </Grid>
                    {errors.employee_id && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.employee_id?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* password*/}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="employee_password"
                        className={classes.labelText}
                      >
                        Password:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="employee_password"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="employee_password"
                        type="password"
                      />
                    </Grid>
                    {errors.employee_password && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.employee_password?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* কর্মচারী আইডি নাম্বার (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="employee_id_bn"
                        className={classes.labelText}
                      >
                        কর্মচারী আইডি নাম্বার (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="employee_id_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="employee_id_bn"
                      />
                    </Grid>
                    {errors.employee_id_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.employee_id_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="name" className={classes.labelText}>
                        Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="name"
                      />
                    </Grid>
                    {errors.name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* নাম (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="name_bn"
                        className={classes.labelText}
                      >
                        নাম (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="name_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="name_bn"
                      />
                    </Grid>
                    {errors.name_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.name_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Personal File No */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="personal_file_no"
                        className={classes.labelText}
                      >
                        Personal File No:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="personal_file_no"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="personal_file_no"
                      />
                    </Grid>

                    {errors.personal_file_no && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.personal_file_no?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Present Designation */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="present_designation"
                        className={classes.labelText}
                      >
                        Present Designation:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <select
                        className={classes.dropDown}
                        name="present_designation"
                        defaultValue=""
                        ref={register({
                          required: "This Field is Required",
                        })}
                      >
                        <option value="" disabled>
                          Select Designation
                        </option>
                        {designations &&
                          designations.map((d, index) => {
                            return (
                              <option value={d.designation} key={index}>
                                {d.designation}
                              </option>
                            );
                          })}
                      </select>
                    </Grid>
                    {errors.present_designation && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.present_designation?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* বর্তমান পদবী (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="present_designation_bn"
                        className={classes.labelText}
                      >
                        বর্তমান পদবী (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="present_designation_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="present_designation_bn"
                      />
                    </Grid>
                    {errors.present_designation_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.present_designation_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Present Status */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="present_status"
                        className={classes.labelText}
                      >
                        Present Status:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="present_status"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="present_status"
                      />
                    </Grid>
                    {errors.present_status && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.present_status?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Mobile Number */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="mobile_no"
                        className={classes.labelText}
                      >
                        Mobile Number:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="mobile_no"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="mobile_no"
                      />
                    </Grid>
                    {errors.mobile_no && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.mobile_no?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* মোবাইল নাম্বার (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="mobile_no_bn"
                        className={classes.labelText}
                      >
                        মোবাইল নাম্বার (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="mobile_no_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="mobile_no_bn"
                      />
                    </Grid>
                    {errors.mobile_no_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.mobile_no_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* E-mail */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="email" className={classes.labelText}>
                        E-mail:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="email"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="email"
                      />
                    </Grid>
                    {errors.email && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.email?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Present Address */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="present_address"
                        className={classes.labelText}
                      >
                        Present Address:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="present_address"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="present_address"
                      />
                    </Grid>
                    {errors.present_address && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.present_address?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* বর্তমান ঠিকানা (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="present_address_bn"
                        className={classes.labelText}
                      >
                        বর্তমান ঠিকানা (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="present_address_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="present_address_bn"
                      />
                    </Grid>
                    {errors.present_address_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.present_address_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Permanent Address */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="permanent_address"
                        className={classes.labelText}
                      >
                        Permanent Address:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="permanent_address"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="permanent_address"
                      />
                    </Grid>
                    {errors.permanent_address && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.permanent_address?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* স্থায়ী ঠিকানা (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="permanent_address_bn"
                        className={classes.labelText}
                      >
                        স্থায়ী ঠিকানা (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="permanent_address_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="permanent_address_bn"
                      />
                    </Grid>
                    {errors.permanent_address_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.permanent_address_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Academic Qualification */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="academic_qualification"
                        className={classes.labelText}
                      >
                        Academic Qualification:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="academic_qualification"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="academic_qualification"
                      />
                    </Grid>
                    {errors.academic_qualification && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.academic_qualification?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Job Experience */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="job_experience"
                        className={classes.labelText}
                      >
                        Job Experience:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="job_experience"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="job_experience"
                      />
                    </Grid>
                    {errors.job_experience && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.job_experience?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Upload Image */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="profile_image"
                        className={classes.labelText}
                      >
                        Upload Image:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      {/* <TextField
                        id="profile_image"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="profile_image"
                      /> */}

                      <div className="img-holder">
                        <input
                          type="file"
                          accept="image/*"
                          name="profile_image"
                          id="input"
                          onChange={(e) => imageHandler(e)}
                        />
                        <img src={picture} alt="" id="img" className="img" />
                      </div>
                    </Grid>
                    {errors.profile_image && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.profile_image?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Father's Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="father_name"
                        className={classes.labelText}
                      >
                        Father's Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="father_name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="father_name"
                      />
                    </Grid>
                    {errors.father_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.father_name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* পিতার নাম (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="father_name_bn"
                        className={classes.labelText}
                      >
                        পিতার নাম (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="father_name_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="father_name_bn"
                      />
                    </Grid>
                    {errors.father_name_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.father_name_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Mother's Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="mother_name"
                        className={classes.labelText}
                      >
                        Mother's Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="mother_name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="mother_name"
                      />
                    </Grid>
                    {errors.mother_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.mother_name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* মাতার নাম (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="mother_name_bn"
                        className={classes.labelText}
                      >
                        মাতার নাম (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="mother_name_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="mother_name_bn"
                      />
                    </Grid>
                    {errors.mother_name_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.mother_name_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Upload Signature */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="signature"
                        className={classes.labelText}
                      >
                        Upload Signature:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      {/* <TextField
                        id="signature"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="signature"
                      /> */}
                      <div className="img-holder">
                        <input
                          type="file"
                          accept="image/*"
                          name="signature"
                          id="input"
                          onChange={(e) => imageHandler(e)}
                        />
                        <img
                          src={signatureImg}
                          alt=""
                          id="img"
                          className="img"
                        />
                      </div>
                    </Grid>
                    {errors.signature && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.signature?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Marital Status */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="marital_status"
                        className={classes.labelText}
                      >
                        Marital Status:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="marital_status"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="marital_status"
                      />
                    </Grid>
                    {errors.marital_status && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.marital_status?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* No. of Children */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="no_of_children"
                        className={classes.labelText}
                      >
                        No. of Children:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="no_of_children"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="no_of_children"
                      />
                    </Grid>
                    {errors.no_of_children && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.no_of_children?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Home District */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="home_district"
                        className={classes.labelText}
                      >
                        Home District:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="home_district"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="home_district"
                      />
                    </Grid>
                    {errors.home_district && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.home_district?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Religion */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="religion"
                        className={classes.labelText}
                      >
                        Religion:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="religion"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="religion"
                      />
                    </Grid>
                    {errors.religion && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.religion?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Gender */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="gender"
                        className={classes.labelText}
                      >
                        Gender:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="gender"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="gender"
                      />
                    </Grid>
                    {errors.gender && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.gender?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Nationality */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="nationality"
                        className={classes.labelText}
                      >
                        Nationality:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="nationality"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="nationality"
                      />
                    </Grid>
                    {errors.nationality && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.nationality?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* National ID Card No */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="nid" className={classes.labelText}>
                        National ID Card No:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="nid"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="nid"
                      />
                    </Grid>
                    {errors.nid && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.nid?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Personal Identification No. of Birth Certificate */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="bid" className={classes.labelText}>
                        Personal Identification No. of Birth Certificate:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="bid"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="bid"
                      />
                    </Grid>
                    {errors.bid && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.bid?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Blood Group */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="blood_group"
                        className={classes.labelText}
                      >
                        Blood Group:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="blood_group"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="blood_group"
                      />
                    </Grid>
                    {errors.blood_group && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.blood_group?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Passport No */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="passport_no"
                        className={classes.labelText}
                      >
                        Passport No:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="passport_no"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="passport_no"
                      />
                    </Grid>
                    {errors.passport_no && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.passport_no?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* e-TIN */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="e_tin" className={classes.labelText}>
                        e-TIN:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="e_tin"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="e_tin"
                      />
                    </Grid>
                    {errors.e_tin && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.e_tin?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Retirement Date */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="retirement_date"
                        className={classes.labelText}
                      >
                        Retirement Date:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="retirement_date"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.retirement_date && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.retirement_date?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* PRL Start Date */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="prl_start_date"
                        className={classes.labelText}
                      >
                        PRL Start Date:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="prl_start_date"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.prl_start_date && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.prl_start_date?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Date of Birth */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="dob" className={classes.labelText}>
                        Date of Birth:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="dob"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              maxDate={new Date()}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.dob && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.dob?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Date of Joining */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="doj" className={classes.labelText}>
                        Date of Joining:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="doj"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.doj && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.doj?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* যোগদানের তারিখ (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="doj_bn"
                        className={classes.labelText}
                      >
                        যোগদানের তারিখ (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="doj_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="doj_bn"
                      />
                    </Grid>
                    {errors.doj_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.doj_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Joining Designation */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="joining_designation"
                        className={classes.labelText}
                      >
                        Joining Designation:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="joining_designation"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="joining_designation"
                      />
                    </Grid>
                    {errors.joining_designation && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.joining_designation?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* যোগদান পদবী (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="joining_designation_bn"
                        className={classes.labelText}
                      >
                        যোগদান পদবী (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="joining_designation_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="joining_designation_bn"
                      />
                    </Grid>
                    {errors.joining_designation_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.joining_designation_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Present Place of Posting */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="present_place_of_posting"
                        className={classes.labelText}
                      >
                        Present Place of Posting:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="present_place_of_posting"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="present_place_of_posting"
                      />
                    </Grid>
                    {errors.present_place_of_posting && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.present_place_of_posting?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* Work Place */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="work_place"
                        className={classes.labelText}
                      >
                        Work Place:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="work_place"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="work_place"
                      />
                    </Grid>
                    {errors.work_place && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.work_place?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* Unit Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="unit_name"
                        className={classes.labelText}
                      >
                        Unit Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <select
                        className={classes.dropDown}
                        name="unit_name"
                        defaultValue=""
                        ref={register({
                          required: "This Field is Required",
                        })}
                      >
                        <option value="" disabled>
                          Select Unit Name
                        </option>
                        {unitNames &&
                          unitNames.map((d, index) => {
                            return (
                              <option value={d.unit_name} key={index}>
                                {d.unit_name}
                              </option>
                            );
                          })}
                      </select>
                    </Grid>
                    {errors.unit_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.unit_name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Joining Date at Present Place */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="joining_date_at_present_place"
                        className={classes.labelText}
                      >
                        Joining Date at Present Place:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="joining_date_at_present_place"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.joining_date_at_present_place && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.joining_date_at_present_place?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Last Promotion Date */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="last_promotion_date"
                        className={classes.labelText}
                      >
                        Last Promotion Date:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="last_promotion_date"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.last_promotion_date && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.last_promotion_date?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Language(s) Known */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="language_known"
                        className={classes.labelText}
                      >
                        Language(s) Known:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="language_known"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="language_known"
                      />
                    </Grid>
                    {errors.language_known && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.language_known?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Training Period */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="training_period"
                        className={classes.labelText}
                      >
                        Training Period:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="training_period"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="training_period"
                      />
                    </Grid>
                    {errors.training_period && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.training_period?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Letter Issue Date */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="letter_issue_date"
                        className={classes.labelText}
                      >
                        Letter Issue Date:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="letter_issue_date"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.letter_issue_date && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.letter_issue_date?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Duty Time */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="duty_time"
                        className={classes.labelText}
                      >
                        Duty Time:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="duty_time"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="duty_time"
                      />
                    </Grid>
                    {errors.duty_time && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.duty_time?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* Card Date of Issue */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="card_date_of_issue"
                        className={classes.labelText}
                      >
                        Card Date of Issue:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="card_date_of_issue"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.card_date_of_issue && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.card_date_of_issue?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Card Validity Period */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="card_validity_period_start"
                        className={classes.labelText}
                      >
                        Card Validity Period:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            name="card_validity_period_start"
                            control={control}
                            initialFocusedDate={null}
                            defaultValue={null}
                            render={({ ref, ...rest }) => (
                              <KeyboardDatePicker
                                className={clsx(
                                  classes.textFieldInput,
                                  classes.cardValidityPeriodWidth
                                )}
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                {...rest}
                              />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                        {errors.card_validity_period_start && (
                          <Grid item xs={12} className={classes.errorDiv}>
                            <span className={classes.errorMessage}>
                              * {errors.card_validity_period_start?.message}
                            </span>
                          </Grid>
                        )}
                        <span className={classes.toText}>To</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            name="card_validity_period_end"
                            control={control}
                            initialFocusedDate={null}
                            defaultValue={null}
                            render={({ ref, ...rest }) => (
                              <KeyboardDatePicker
                                className={clsx(
                                  classes.textFieldInput,
                                  classes.cardValidityPeriodWidth
                                )}
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                {...rest}
                              />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                        {errors.card_validity_period_end && (
                          <Grid item xs={12} className={classes.errorDiv}>
                            <span className={classes.errorMessage}>
                              * {errors.card_validity_period_end?.message}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>

                {/* Total Salary */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel htmlFor="name" className={classes.labelText}>
                        Total Salary:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="total_salary"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only Numbers Allow",
                          },
                        })}
                        name="total_salary"
                      />
                    </Grid>
                    {errors.total_salary && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.total_salary?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </Grid>
              {/* Save Button */}
              <ListItem className={classes.inputRow}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
          </form>
        </List>
      </Paper>
    </main>
  );
}

export default withRouter(EmployeeAdd);
