import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import MaterialTable from "material-table";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { AXIOS_BASE_URL, SERVER_URL } from "../../constants/constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import API from "../../common/api";
import { setDayWithOptions } from "date-fns/fp";
import { Link, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(1, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}));
function EmployeeViewDocument() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const { control, register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();



  const watchSalaryInfoSalaryType = watch("salary_info_salary_type", false);

  const [columns, setColumns] = useState([
    {
      title: "Sl",
      field: "id",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
        marginBottom: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Path",
      field: "file_info_path",
      hidden: true,
    },
    {
      title: "File Name",
      field: "file_info_name",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [dataFormSend, setDataFormSend] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchError, setSearchError] = useState("");
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);

  const onSubmitSearch = async (data) => {
    console.log(JSON.stringify(data));

    if (data.employee_id === "" && data.name === "" && data.mobile_no === "") {
      setData([]);
      setSearchError("* Employee ID or Name or Mobile NO Required!");
    } else {
      
    }
  };

  const downloadFileById = async (id_pk) => {
    // setIsDisableBtn(true);
    const res = await API.get(`/users/userFileDownloadById/${id_pk}`);
    console.log("res");
    console.log(res.file);
    //  `https://expressoneltd.com/api/users/userFileDownloadById/${id_pk}` live
    //uploads/userFiles/1/123-1637435552123-149477824.png
    if (res.success === 1) {
      window.open(res.file);
    }
  };
  const deleteFileById = async (id_pk) => {
    setIsDisableBtn(true);
    const res = await API.delete(`/users/deleteUserFile/${id_pk}`);
    if (data.length === 1) {
      history.go(0);
    }
    if (res) {
      setIsDisableBtn(false);
      onSubmitSearch(dataFormSend);
      // history.go(0);
    }
  };

  const [permission, setPermission] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  const location = useLocation();

  useEffect(async () => {
    setIsLoading(true);

    //   console.log("location: " + JSON.stringify(location));
    console.log("EmployeeViewDocument ID: " + location.state.id);
  
    // const id = parseInt(location.state.id);
    const id = location.state.id;

    if(id){
        const data = {employee_id: id, name: "", mobile_no: ""}
      const res = await API.post("/users/searchUserFiles", data);

      setDataFormSend(data);
      if (res.success === 1) {
        console.log("RES: " + JSON.stringify(res.data));
        setData(res.data);
        setEmployeeId(res.data[0].employee_id);
        setSearchError("");
      }
    }

   

    const app_user_permission = localStorage.getItem("app_user_permission");
    if (app_user_permission) {
      setPermission(app_user_permission);
      setIsLoading(false);
    }
  }, [permission]);

  const getActions = () => {
    const actions = [];
    if (permission.includes("R")) {
      actions.push({
        icon: () => <GetAppOutlinedIcon />,
        tooltip: "Download File",
        onClick: (event, rowData) => {
          downloadFileById(rowData.id_pk);
        },
      });
    }

    if (permission.includes("D")) {
      actions.push({
        icon: () => <ClearOutlinedIcon />,
        tooltip: "Remove File",
        onClick: (event, rowData) => {
          deleteFileById(rowData.id_pk);
        },
      });
    }

    return actions;
  };
  return (
    <main className={classes.content}>

         <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />

    <div>
      {/* SEARCH EMPLOYEES FILES START */}
      <h1>View Document</h1>
      <Paper className={classes.resultTable}>
        <MaterialTable
          title={`Employee Id: ${employeeId}`}
          columns={columns}
          data={data}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          options={{
            exportButton: false,
            search: false,
            paging: false,
            actionsColumnIndex: -1,
            headerStyle: {
              textAlign: "left",
              paddingRight: "32px",
            },

            actionsCellStyle: {
              paddingRight: "32px",
              justifyContent: "center",
            },
          }}
          actions={!isLoading && getActions()}
        />
      </Paper>
    </div>
    </main>
  );
}

export default EmployeeViewDocument;
