import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm, Controller } from "react-hook-form";
import API from "../common/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."} {"All Rights Reserved by "}
      <Link color="primary" to="https://expressoneltd.com/">
        Express One ltd.
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  registerText: {
    color: "#2E3192",
    fontFamily: "cursive",
    fontSize: "16px",
  },
  loginText: {
    color: "#2E3192",
    fontFamily: "cursive",
    fontSize: "16px",
  },
}));

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateMobile = (mobile) => {
  return String(mobile)
    .toLowerCase()
    .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
};

const saveItemInLocalStorage = (res) => {
  localStorage.setItem("accessToken", res.accessToken);
  localStorage.setItem("app_user_first_name", res.data.app_user_first_name);
  localStorage.setItem("app_user_last_name", res.data.app_user_last_name);
  localStorage.setItem("app_user_email", res.data.app_user_email);
  localStorage.setItem("app_user_mobile", res.data.app_user_mobile);
  localStorage.setItem("app_user_permission", res.data.app_user_permission);
};
export default function Login() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    // console.log(JSON.stringify(data));
    const result = {
      app_user_email: validateEmail(data.username) ? data.username : "",
      app_user_mobile: validateMobile(data.username) ? data.username : "",
      app_user_password: data.password,
    };

    // console.log(result);
    const res = await API.post("/auth/login", result);

    if (res.success === 1) {
      setIsLoading(false);
      // console.log("accessToken: " + res.accessToken);

      saveItemInLocalStorage(res);

      history.push("/employee");
    } else {
      setIsLoading(false);
      toast.error(res.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <img
          src="https://expressoneltd.com/images/Logo.png"
          alt="ExpressOneLtdImg"
          width="400"
          height="100"
        />

        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email / Mobile no"
            name="username"
            autoFocus
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.username && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.username?.message}
              </span>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.password && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.password?.message}
              </span>
            </Grid>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            Log In
          </Button>
          <span className={classes.registerText}>
            Don't have an account? Please{" "}
            <Link to="/registration">Register</Link>
          </span>
          <br />
          <span className={classes.loginText}>
            Admin Panel <Link to="/admin">Login</Link>
          </span>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
