export const employeeButtonLabel = {
  add: "Add Employee Info",
  importBulk: "",
  viewAll: "Employees",
  search: "",
  setting: "",
  idCard: "ID Card",
  salaryInfo: "Add Salary Info",
  fileUpload: "Documents",
};

export const guarantorButtonLabel = {
  add: "Add Guarantor",
  importBulk: "",
  viewAll: "View All",
  search: "Search",
  setting: "",
};
