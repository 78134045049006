import { Button, Card, makeStyles } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
  },
  pageHeader: {
    margin: theme.spacing(2, 0, 0, 0),
    display: "flex",
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    paddingLeft: theme.spacing(4),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

export default function SubmenuButtons({ routes, buttonLabelName }) {
  const classes = useStyles();
  let history = useHistory();
  // console.log(JSON.stringify(routes));
  const matches = useMediaQuery("(min-width:600px)");
  
  const [permission, setPermission] = useState("");

  useEffect(() => {
    const app_user_permission = localStorage.getItem("app_user_permission");
    if (app_user_permission) {
      setPermission(app_user_permission);
    }
  }, [permission]);

  return (
    <div className={classes.pageHeader}>
      <Card className={classes.pageIcon}>
        <Grid container direction={matches ? "row" : "column"}>
          {buttonLabelName.add && permission.includes("C") && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<PersonAddOutlinedIcon />}
                onClick={() => {
                  history.push(routes.add);
                }}
              >
                {buttonLabelName.add}
              </Button>
            </Grid>
          )}
          {buttonLabelName.salaryInfo && permission.includes("C") && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<LocalAtmOutlinedIcon />}
                onClick={() => {
                  history.push(routes.salaryInfo);
                }}
              >
                {buttonLabelName.salaryInfo}
              </Button>
            </Grid>
          )}

          {buttonLabelName.importBulk && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<ImportExportIcon />}
                onClick={() => {
                  history.push(routes.importBulk);
                }}
              >
                {buttonLabelName.importBulk}
              </Button>
            </Grid>
          )}
          {buttonLabelName.viewAll && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<ViewListOutlinedIcon />}
                onClick={() => {
                  history.push(routes.viewAll);
                }}
              >
                {buttonLabelName.viewAll}
              </Button>
            </Grid>
          )}
          {buttonLabelName.fileUpload && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<PublishOutlinedIcon />}
                onClick={() => {
                  history.push(routes.fileUpload);
                }}
              >
                {buttonLabelName.fileUpload}
              </Button>
            </Grid>
          )}
          {buttonLabelName.search && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<SearchOutlinedIcon />}
                onClick={() => {
                  history.push(routes.search);
                }}
              >
                {buttonLabelName.search}
              </Button>
            </Grid>
          )}
          {buttonLabelName.setting && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<SettingsOutlinedIcon />}
              >
                {buttonLabelName.setting}
              </Button>
            </Grid>
          )}
          {buttonLabelName.idCard && permission.includes("C") && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<PermContactCalendarOutlinedIcon />}
                onClick={() => {
                  history.push(routes.idCard);
                }}
              >
                {buttonLabelName.idCard}
              </Button>
            </Grid>
          )}
          {buttonLabelName.helpCenterAdd && permission.includes("C") && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<HelpOutlineOutlinedIcon />}
                onClick={() => {
                  history.push(routes.helpCenterAdd);
                }}
              >
                {buttonLabelName.helpCenterAdd}
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
}
